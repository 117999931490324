import React from "react";
import { useState } from "react";
import InputGroup from "./inputGroup";
import axios from 'axios';
import useAuth from "../hooks/useAuth";
import { axiosPrivate } from "../api/axiosPrivate";



const Register = (props) => {
    const { auth } = useAuth()
    const [name, setName] = useState("")
    const [SurName, setSurName] = useState("")
    const [telephone, setTelephone] = useState("")
    const [activite, setActivite] = useState("")
    const [adressActivite, setAddressactivite] = useState("")
    const [commune, setCommune] = useState("")
    const [birthday, setBirth] = useState("")


    const annuler = () => {
        setName("");
        setSurName("");
        setTelephone("");
        setActivite("");
        setAddressactivite("");
        setCommune("");
        setBirth("");

    };
    const validUser = async (e) => {
        e.preventDefault();
        console.log("auth", auth);

        try {

            const userInfo = {
                surname: SurName,
                name: name,
                birthday: birthday,
                address_activity: adressActivite,
                commune: commune,
                activity: activite,
                telephone: parseInt(telephone)
            }

            const isObjectNotEmpty = (object) => {
                if (typeof object !== 'object' || object === null) {
                    return false;
                }

                return Object.values(object).every(value => {
                    if (value === null || value === undefined) {
                        return false;
                    }
                    if (typeof value === 'string' && value.trim() === '') {
                        return false;
                    }
                    if (Array.isArray(value) && value.length === 0) {
                        return false;
                    }
                    if (typeof value === 'object' && Object.keys(value).length === 0) {
                        return false;
                    }
                    return true;
                });
            };

            if (isObjectNotEmpty(userInfo) !== true) {
                console.log("userInfo : ", userInfo);
                return (
                    alert("probleme de saisi")
                )
            }

            console.log("userInfo : ", userInfo);

            const response = await axiosPrivate.post('http://localhost:8000/Add/user', userInfo)

            if (
                response.status === 201
            ) {
                console.log("userInfo", userInfo);
                alert("user be add !")
                annuler()


            }



        } catch (error) {
            console.log("error :", error);
            alert(error.response)

        }
    }

    return (
        <div className="mt-5 container border" >
            <form className="pt-5">
                <div className=" mb-3">
                    <label for="exampleInputEmail1" className="form-label">Nom</label>
                    <input
                        type="text" className="form-control" id="name" placeholder="sow"
                        onChange={(e) => setName(e.target.value)} value={name}
                    />

                </div>
                <div className=" mb-3">
                    <label for="exampleInputEmail1" className="form-label">Prenom</label>
                    <input
                        type="text" className="form-control" id="surname" placeholder="abdou"
                        onChange={(e) => setSurName(e.target.value)} value={SurName}
                    />

                </div>
                <div className="mb-3">
                    <label className="form-label">Telephone</label>
                    <input
                        type="number" className="form-control" id="number" placeholder="771231010"
                        onChange={(e) => setTelephone(e.target.value)} value={telephone}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Activitee</label>
                    <div className="input-group" onChange={(e) => setActivite(e.target.value)} value={activite}>
                        <InputGroup
                            name="activities" />
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">Adresse d'activitee</label>
                    <div className="input-group" onChange={(e) => setAddressactivite(e.target.value)} value={adressActivite}>
                        <InputGroup
                            name="localities" />
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">Commune</label>
                    <input
                        type="text" className="form-control" id="commune" placeholder="Sebikotane"
                        onChange={(e) => setCommune(e.target.value)} value={commune}

                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Date de naissance</label>
                    <input
                        type="date" className="form-control" id="Birthday"
                        onChange={(e) => setBirth(e.target.value)} value={birthday}

                    />
                </div>
                <button type="submit" className="btn btn-primary" onClick={validUser} >Submit</button>
            </form>
        </div>
    )
}

export default Register 