import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import './login.css'; // Assurez-vous d'importer le fichier CSS

const Login = () => {
    const { setAuth } = useAuth();  
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/'; 

    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState("");
    const [psw, setPsw] = useState("");
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg("");
    }, [email, psw]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const agentInfo = {
                email,
                pwd: psw
            };

            const response = await axios.post("http://localhost:8000/auth", agentInfo, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            });

            const accessToken = response?.data?.accessToken;
            const role = response?.data?.roleToUse;
            const surname = response?.data?.surnameToUse;

            setAuth({ email, accessToken, role, surname });

            alert("Vous êtes bien connecté");

            setEmail("");
            setPsw("");

            if (role === 2) {
                navigate("/Agent");
            } else if (role === 1) {
                navigate("/Admin");
            } else {
                navigate(from, { replace: true });
            }

        } catch (err) {
            if (!err?.response) {
                setErrMsg("Pas de réponse du serveur");
            } else if (err.response?.status === 400) {
                setErrMsg("Email ou mot de passe manquant");
            } else if (err.response?.status === 401) {
                setErrMsg("Non autorisé");
            } else {
                setErrMsg("Échec de la connexion");
            }
            errRef.current.focus();
        }
    };

    return (
        <div className="login-container">
            <div className="login-image"></div>
            <div className="login-form">
                <form onSubmit={handleSubmit}>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                        {errMsg}
                    </p>
                    <h1>Connexion</h1>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Adresse Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            ref={userRef}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                        />
                        <div id="emailHelp" className="form-text">Nous ne partagerons jamais votre email avec quelqu'un d'autre.</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Mot de passe</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            onChange={(e) => setPsw(e.target.value)}
                            value={psw}
                            required
                        />
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" htmlFor="exampleCheck1">Cochez moi</label>
                    </div>
                    <button type="submit" className="btn btn-primary">Se Connecter</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
