import React, { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Localities = () => {

    const axiosPrivate = useAxiosPrivate();
    const [changedlocalities, setChangeUsers] = useState([]); // State for validated localities

    const [localities, setLocalities] = useState([]);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [newName, setNewName] = useState("");

    const modifier = () => {
        const changedLocality = {
            id,
            name,
        };
        console.log("je suis cliquer modifier");
        console.log("changedLocality", changedLocality);
        let isMounted = true;
        const controller = new AbortController();
        const Updatelocality = async () => {
            try {
                const response = await axiosPrivate.put(`/locality/${id}`, changedLocality, {
                    signal: controller.signal
                });
                console.log(response.data);
                alert("Modification effectuée");
            } catch (error) {
                console.log(error);
            }
        };
        Updatelocality();
        retourModal();

        return () => {
            isMounted = false;
            controller.abort();
        };
    };

    const addLocality = () => {
        const newLocality = {
            name: newName,
        };
        let isMounted = true;
        const controller = new AbortController();
        const createLocality = async () => {
            try {
                const response = await axiosPrivate.post('/add/locality', newLocality, {
                    signal: controller.signal
                });
                console.log(response.data);
                if (isMounted) {
                    setLocalities((prevLocalities) => [...prevLocalities, response.data]);
                    alert("Localité ajoutée");
                }
            } catch (error) {
                console.log(error);
            }
        };
        createLocality();
        retourNewModal();

        return () => {
            isMounted = false;
            controller.abort();
        };
    };

    const handchange = (locality) => {
        console.log("locality :", locality);
        setId(locality._id);
        setName(locality.name);
        setChangeUsers((prevChangeLocalities) => [...prevChangeLocalities, locality]);
        console.log("changedlocalities :", changedlocalities);
    };

    const retourModal = () => {
        setId("");
        setName("");
    };

    const retourNewModal = () => {
        setNewName("");
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getLocalities = async () => {
            try {
                const response = await axiosPrivate.get('/localities', {
                    signal: controller.signal
                });
                console.log(response.data);
                isMounted && setLocalities(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getLocalities();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, localities]);

    useEffect(() => { // modal
        const myModalElement = document.getElementById('exampleModal');
        if (myModalElement) {
            const myModal = new Modal(myModalElement);
            //myModal.show();
        }
    }, []);

    return (
        <div className="container-fluid">
            <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                <div className="badge text-bg-primary" style={{ float: "left" }}>
                    <p>Nombre Localites</p>
                    <span>{localities.length}</span>
                </div>
            </div>

            <table className="table border">
                <thead>
                    <tr>
                        <th scope="col">Nom</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {localities.map(locality => (
                        <tr key={locality._id}>
                            <td>{locality.name}</td>
                            <td><button className="text-bg-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" name={localities.name} onClick={() => handchange(locality)}>Modifier</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                <button type="button" className="text-bg-warning" data-bs-toggle="modal" data-bs-target="#addActivityModal" style={{ float: "left" }}>
                    Ajouter une localité
                </button>

                {/* Modal pour modifier une localite */}
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Nom</span>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                                </div>
                            </div>
                            <div className="modal-footer" style={{ display: "block" }}>
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={retourModal}>Fermer</button>
                                <button type="button" className="btn btn-success" onClick={modifier}>Modifier</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal pour ajouter une nouvelle activité */}
                <div className="modal fade" id="addActivityModal" tabIndex="-1" aria-labelledby="addActivityModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Nom</span>
                                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} className="form-control" />
                                </div>
                            </div>
                            <div className="modal-footer" style={{ display: "block" }}>
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={retourNewModal}>Fermer</button>
                                <button type="button" className="btn btn-success" onClick={addLocality}>Ajouter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Localities;
