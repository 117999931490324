import React from "react";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"



const AddAdmin = () => {
    const axiosPrivate = useAxiosPrivate()
    const [name, setName] = useState("")
    const [SurName, setSurName] = useState("")
    const [telephone, setTelephone] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [birthday, setBirth] = useState("")


    const annuler = () => {
        setName("");
        setSurName("");
        setTelephone("");
        setEmail("");
        setPassword("");
        setBirth("");

    };
    const validUser = async (e) => {
        e.preventDefault();

        try {

            const AdminInfo = {
                surname: SurName,
                name: name,
                birthday: birthday,
                email: email,
                password: password,
                role: 1,
                telephone: parseInt(telephone)
            }

            const isObjectNotEmpty = (object) => {
                if (typeof object !== 'object' || object === null) {
                    return false;
                }

                return Object.values(object).every(value => {
                    if (value === null || value === undefined) {
                        return false;
                    }
                    if (typeof value === 'string' && value.trim() === '') {
                        return false;
                    }
                    if (Array.isArray(value) && value.length === 0) {
                        return false;
                    }
                    if (typeof value === 'object' && Object.keys(value).length === 0) {
                        return false;
                    }
                    return true;
                });
            };

            if (isObjectNotEmpty(AdminInfo) !== true) {
                console.log("AdminInfo : ", AdminInfo);
                return (
                    alert("probleme de saisi")
                )
            }

            console.log("AdminInfo : ", AdminInfo);

            const response = await axiosPrivate.post('add/admin-agent', AdminInfo)

            if (
                response.status === 201
            ) {
                console.log("AdminInfo", AdminInfo);
                alert("admin be add !")
                annuler()


            }



        } catch (error) {
            console.log("error :", error);
            alert(error.response)

        }
    }

    return (
        <div className="mt-5 container border" >
            <form className="pt-5">
                <div className=" mb-3">
                    <label for="exampleInputEmail1" className="form-label">Nom</label>
                    <input
                        type="text" className="form-control" id="name" placeholder="sow"
                        onChange={(e) => setName(e.target.value)} value={name}
                    />

                </div>
                <div className=" mb-3">
                    <label for="exampleInputEmail1" className="form-label">Prénom</label>
                    <input
                        type="text" className="form-control" id="surname" placeholder="abdou"
                        onChange={(e) => setSurName(e.target.value)} value={SurName}
                    />

                </div>
                <div className="mb-3">
                    <label className="form-label">Téléphone</label>
                    <input
                        type="number" className="form-control" id="number" placeholder="771231010"
                        onChange={(e) => setTelephone(e.target.value)} value={telephone}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input
                        type="email" className="form-control" id="email" placeholder="dou@gmail.com"
                        onChange={(e) => setEmail(e.target.value)} value={email}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Mot de passe</label>
                    <input
                        type="password" className="form-control" id="commune" placeholder="********"
                        onChange={(e) => setPassword(e.target.value)} value={password}

                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Date de naissance</label>
                    <input
                        type="date" className="form-control" id="Birthday"
                        onChange={(e) => setBirth(e.target.value)} value={birthday}

                    />
                </div>
                <button type="submit" className="btn btn-primary" onClick={validUser} >Modifier</button>
            </form>
        </div>
    )
}

export default AddAdmin 