import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Admin.css';

function Circle({ color, label, icon }) {
  return (
    <div className={`circle ${color}`}>
      <i className={`${icon} large-icon`}></i>
      <div>{label}</div>
    </div>
  );
}

export default Circle;
