import React, { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate.js";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const MesAgents = () => {
    const axiosPrivate = useAxiosPrivate();

    const [agents, setAgents] = useState([]);
    const [changedAgent, setChangeAgent] = useState([]);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [birthday, setBirthday] = useState("");
    const [password, setPassword] = useState("");
    const [telephoneToSearch, setTelephoneSearch] = useState("");

    const handchange = (agent) => {
        console.log("agent :", agent);
        setName(agent.name || "");
        setId(agent._id)
        setSurname(agent.surname || "");
        setEmail(agent.email || "");
        setTelephone(agent.telephone || "");
        setBirthday(agent.birthday ? agent.birthday.slice(0, 10) : "");
        setPassword(agent.password || "");
        setChangeAgent((prevChangeAgents) => [...prevChangeAgents, agent]);
        console.log("changedUsers :", changedAgent);
    };

    const modifier = () => {
        const changedAdminAgent = {
            id,
            name,
            surname,
            birthday,
            telephone,
            email,
            password,
            role: 2
        }
        console.log("je suis cliquer modifier");
        let isMounted = true;
        const controller = new AbortController()
        console.log("changedUsers :", changedAdminAgent);
        const UpdateUsers = async () => {
            try {
                const response = await axiosPrivate.put(`/adminAgent/${id}`, changedAdminAgent, {
                    signal: controller.signal
                });
                console.log(response.data);
                alert("modification effectuer")
            } catch (error) {
                console.log(error);
            }
        }
        UpdateUsers();
        retourModal();

        return () => {
            isMounted = false;
            controller.abort()
        }
    }

    const recherche = async () => {
        console.log("je suis cliquer", telephoneToSearch);
        const numero = parseInt(telephoneToSearch);
        console.log("je suis cliquer le type", typeof numero);
        console.log("je suis cliquer le length", telephoneToSearch.length);
        console.log("je suis ", parseInt(telephoneToSearch));

        if (isNaN(numero)) {
            alert("Veuillez entrer un numéro de téléphone valide");
        } else if (telephoneToSearch.length !== 9) {
            alert("Veuillez entrer un numéro de téléphone avec 9 chiffres sans espace");
        } else {
            const agentfind = agents.find(agent => agent.telephone === numero);
            console.log("agentfind :", agentfind);
            if (agentfind === undefined) {
                alert("Ce numéro n'existe pas dans la base de données");
            } else {
                setAgents([agentfind]);
                console.log("Users:", agents);
            }
        }
    }

    const effacerRecherche = () => {
        console.log("je suis dans effacerRecherche");
        setTelephoneSearch("");
    }

    const retourModal = () => {
        setName("");
        setSurname("");
        setEmail("");
        setTelephone("");
        setBirthday("");
        setPassword("");
    }

    const AjouterAgent = async (e) => {
        e.preventDefault();

        try {
            const AgentInfo = {
                surname: surname,
                name: name,
                birthday: birthday,
                email: email,
                role: 2,
                password: password,
                telephone: parseInt(telephone)
            };

            const isObjectNotEmpty = (object) => {
                if (typeof object !== 'object' || object === null) {
                    return false;
                }

                return Object.values(object).every(value => {
                    if (value === null || value === undefined) {
                        return false;
                    }
                    if (typeof value === 'string' && value.trim() === '') {
                        return false;
                    }
                    if (Array.isArray(value) && value.length === 0) {
                        return false;
                    }
                    if (typeof value === 'object' && Object.keys(value).length === 0) {
                        return false;
                    }
                    return true;
                });
            };

            if (isObjectNotEmpty(AgentInfo) !== true) {
                console.log("AgentInfo : ", AgentInfo);
                return alert("Problème de saisie");
            }
            console.log("AgentInfo : ", AgentInfo);

            const response = await axiosPrivate.post('/add/admin-agent', AgentInfo);
            console.log("response.status", response.status);
            if (response.status === 201) {
                alert("Agent ajouté !");
                retourModal();
            }
        } catch (error) {
            console.log("error :", error);
            alert(error.response);
        }
    }

    useEffect(() => {
        const getAgents = async () => {
            try {
                const response = await axiosPrivate.get('/agents');
                console.log("response : ", response.data);
                setAgents(response.data.agents);
            } catch (err) {
                console.log(err);
            }
        };
        getAgents();
    }, [axiosPrivate, agents]);

    useEffect(() => {                                   // modal
        const myModalElement1 = document.getElementById('exampleModal1');
        const myModalElement2 = document.getElementById('exampleModal2');
        if (myModalElement1) {
            const myModal1 = new Modal(myModalElement1);
        }
        if (myModalElement2) {
            const myModal2 = new Modal(myModalElement2);
        }
    }, []);

    return (
        <div>
            <div className="container">
                <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                    <div className="badge text-bg-primary" style={{ float: "left" }}>
                        <p>Mes agents</p>
                        <span>{agents.length}</span>
                    </div>
                    <div className="input" style={{ textAlign: "center", display: "contents", float: "right" }}>
                        <input style={{ marginTop: "10px" }} placeholder="Chercher avec numéro" onChange={(e) => setTelephoneSearch(e.target.value)} />
                        <button onClick={recherche}>Rechercher</button>
                        <button onClick={effacerRecherche}>Effacer</button>
                    </div>
                </div>

                <table className="table border">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Prénom</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {agents.map(agent => (
                            <tr key={agent.telephone}>
                                <td>{agent.name}</td>
                                <td>{agent.surname}</td>
                                <td>{agent.telephone}</td>
                                <td>
                                    <button className="text-bg-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{ float: "right" }} name={agent.name} onClick={() => handchange(agent)}>Modifier</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                    <button type="button" className="text-bg-warning" data-bs-toggle="modal" data-bs-target="#exampleModal2" style={{ float: "left" }}>
                        Ajouter un agent
                    </button>
                    <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Nom</span>
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Prénom</span>
                                        <input type="text" value={surname} onChange={(e) => setSurname(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Email</span>
                                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Téléphone</span>
                                        <input type="text" value={telephone} onChange={(e) => setTelephone(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Mot de passe</span>
                                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Date de naissance</span>
                                        <input type="date" value={birthday} onChange={(e) => setBirthday(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="modal-footer" style={{ display: "block" }}>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={retourModal}>Fermer</button>
                                    <button type="button" className="btn btn-success" onClick={modifier}>Modifier</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Nom</span>
                                        <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Prénom</span>
                                        <input type="text" onChange={(e) => setSurname(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Téléphone</span>
                                        <input type="text" onChange={(e) => setTelephone(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Email</span>
                                        <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Date de naissance</span>
                                        <input type="date" onChange={(e) => setBirthday(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Mot de passe</span>
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="modal-footer" style={{ display: "block" }}>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                                    <button type="button" className="btn btn-success" onClick={AjouterAgent}>Ajouter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MesAgents;
