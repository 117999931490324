import React, { useState } from 'react';

const ValidateButton = ({ disabled, name, onValidate }) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(true);
        onValidate(); // Appeler la fonction de validation transmise depuis le composant Profile
    };

    return (
        <button onClick={handleClick} className={`btn ${isClicked ? 'btn-success' : 'btn-primary'}`} disabled={isClicked}>
            {isClicked ? 'Validé' : 'Valider'}
        </button>
    );
};

export default ValidateButton
