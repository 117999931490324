import React, { createContext, useState } from 'react';

// Créer un contexte pour l'authentification
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Simuler l'état de connexion et le rôle de l'utilisateur
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext

