import React, { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Cloturer = () => {
    const [clotures, setClotures] = useState([]);
    const [activities, setActivities] = useState([]);
    const [localities, setLocalities] = useState([]);
    const [usersByActivity, setUsersByActivity] = useState({});
    const [totalRecouvrementJournalier, setTotalRecouvrementJournalier] = useState(0);
    const axiosPrivate = useAxiosPrivate();
    const clotureRef = useRef(null);
    const usersRef = useRef(null);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                const [cloturesResponse, localitiesResponse, activitiesResponse] = await Promise.all([
                    axiosPrivate.get('/clotures', { signal: controller.signal }),
                    axiosPrivate.get('/localities', { signal: controller.signal }),
                    axiosPrivate.get('/activities', { signal: controller.signal })
                ]);

                if (isMounted) {
                    setClotures(cloturesResponse.data);
                    setLocalities(localitiesResponse.data);
                    setActivities(activitiesResponse.data);

                    // Extraire et fusionner les utilisateurs par activité, localité et agent
                    const users = cloturesResponse.data.flatMap(cloture =>
                        cloture.objets.map(obj => ({
                            ...obj,
                            users: obj.users,
                        }))
                    );

                    // Fusionner les utilisateurs par activité, localité et agent
                    const mergedUsers = users.reduce((acc, obj) => {
                        const key = `${obj.activite}-${obj.locality}-${obj.agent}`;
                        if (!acc[key]) {
                            acc[key] = { ...obj, users: [] };
                        }
                        acc[key].users = acc[key].users.concat(obj.users);
                        acc[key].totalUsers = acc[key].users.length;
                        return acc;
                    }, {});

                    setUsersByActivity(mergedUsers);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, clotures]);

    useEffect(() => {
        const total = Object.values(usersByActivity).reduce((sum, obj) => {
            return sum + (obj.amountActivity * obj.totalUsers);
        }, 0);
        setTotalRecouvrementJournalier(total);
    }, [usersByActivity]);

    const generatePDF = () => {
        // Générer le PDF pour le tableau de clôture
        const clotureInput = clotureRef.current;
        html2canvas(clotureInput).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save("cloture.pdf");
        });

        // Générer le PDF pour le tableau des utilisateurs avec pagination
        const usersInput = usersRef.current;
        html2canvas(usersInput).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save("utilisateurs.pdf");
        });

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                const deletRecouvrementResponse = await axiosPrivate.delete('/delet-recouvrements', { signal: controller.signal });
                const deletCloturesResponse = await axiosPrivate.delete('/delet-clotures', { signal: controller.signal });

                if (isMounted) {
                    console.log("deletRecouvrementResponse", deletRecouvrementResponse);
                    console.log("deletCloturesResponse", deletCloturesResponse);
                    setClotures([]);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
        return () => {
            isMounted = false;
            controller.abort();
        };
    };

    return (
        <div>
            <div ref={clotureRef} className="cloture">
                <h1>Clôture</h1>
                {Object.keys(usersByActivity).map(key => {
                    const obj = usersByActivity[key];
                    return (
                        <div key={key}>
                            <h2>Activité: {obj.activite}</h2>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Localité</th>
                                        <th>Activité</th>
                                        <th>Agent</th>
                                        <th>Nombre de redevables</th>
                                        <th>Valeur du recouvrement</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{obj.locality}</td>
                                        <td>{obj.activite}</td>
                                        <td>{obj.agent}</td>
                                        <td>{obj.totalUsers}</td>
                                        <td>{obj.amountActivity}</td>
                                        <td>{obj.amountActivity * obj.totalUsers}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                })}

                <div className="badge text-bg-success" style={{ float: "right" }}>
                    <p>Total recouvrement journalier</p>
                    <span>{totalRecouvrementJournalier}</span>
                </div>
            </div>
            <br />
            <div ref={usersRef}>
                <h2>Liste des utilisateurs par activité</h2>
                {Object.keys(usersByActivity).map(key => {
                    const obj = usersByActivity[key];
                    return (
                        <div key={key}>
                            <h3>Activité: {obj.activite}</h3>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Prénom</th>
                                        <th>Téléphone</th>
                                        <th>Localité</th>
                                        <th>Montant payé</th>
                                        <th>Agent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {obj.users.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.surname}</td>
                                            <td>{user.name}</td>
                                            <td>{user.telephone}</td>
                                            <td>{obj.locality}</td>
                                            <td>{obj.amountActivity}</td>
                                            <td>{obj.agent}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
            <br />
            <div>
                <button className="badge text-bg-primary" onClick={generatePDF}>Terminer la clôture</button>
            </div>
        </div>
    );
};

export default Cloturer;
