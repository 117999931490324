import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "../hooks/useAuth";
import ValidateButton from "./ValidateButton.jsx";
import { Modal } from "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import InputGroup from "./inputGroup.jsx";

const Agent = () => {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const agentToRec = auth.surname;

    const [recouvrements, setRecouvrements] = useState([]);
    const [users, setUsers] = useState([]);
    const [locality, setLocality] = useState("");
    const [activite, setActivite] = useState("");
    const [userstocover, setUserstocover] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [validatedUsers, setValidatedUsers] = useState([]);

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [commune, setCommune] = useState("");
    const [adressActivite, setAdressActivite] = useState("");
    const [telephone, setTelephone] = useState("");
    const [birthday, setBirthday] = useState("");
    const [activity, setActivity] = useState("");

    const ajouterAnnexe = async () => {
        try {
            const infoAnnexe = {
                name,
                surname,
                commune,
                address_activity: adressActivite,
                telephone,
                birthday,
                activity
            };
            console.log("infoAnnexe :", infoAnnexe);
            const response = await axiosPrivate.post("/Add/annex", infoAnnexe);
            console.log(response);
            alert("Annexe ajoutée");
        } catch (error) {
            console.log(error);
        }
    };

    const effacer = () => {
        setActivite("");
        setLocality("");
    };

    const recouvrement = () => {
        if (locality === "" || activite === "") {
            alert("Veuillez bien choisir une localité et une activité");
        } else {
            setUserstocover(users);
            setIsClicked(true);
            console.log("users to cover :", userstocover);
            console.log("isClicked :", isClicked);
        }
    };

    const handleValidation = (user) => {
        setValidatedUsers((prevValidatedUsers) => [...prevValidatedUsers, user]);
        console.log("validatedUsers :", [...validatedUsers, user]);
    };

    const isUserInRecouvrements = (user) => {
        return recouvrements[activite]?.some(
            recouvrementUser => recouvrementUser.telephone === user.telephone
        );
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        if (locality !== "" && activite !== "") {
            const getUsers = async () => {
                try {
                    const [response, responserecouvrements] = await Promise.all([
                        axiosPrivate.get(`/usersfilter/?activity=${activite}&locality=${locality}`, { signal: controller.signal }),
                        axiosPrivate.get('/clotures', { signal: controller.signal })
                    ]);

                    if (isMounted) {
                        setUsers(response.data);

                        const users = responserecouvrements.data.flatMap(cloture =>
                            cloture.objets.flatMap(obj =>
                                obj.users.map(user => ({
                                    ...user,
                                    activite: obj.activite,
                                    agent: obj.agent,
                                    amountActivity: obj.amountActivity
                                }))
                            )
                        );
                        const groupedUsers = users.reduce((acc, user) => {
                            if (!acc[user.activite]) {
                                acc[user.activite] = [];
                            }
                            acc[user.activite].push(user);
                            return acc;
                        }, {});

                        setRecouvrements(groupedUsers);
                        console.log("groupedUsers)", groupedUsers);
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            getUsers();
            console.log("auth apres fet user :", auth);

            return () => {
                isMounted = false;
                controller.abort();
            };
        }
    }, [activite, locality, axiosPrivate, auth]);

    useEffect(() => {
        const myModalElement = document.getElementById('exampleModal');
        if (myModalElement) {
            const myModal = new Modal(myModalElement);
            // myModal.show();
        }
    }, []);

    const terminerRecouvrement = async () => {
        let isMounted = true;
        const controller = new AbortController();
        console.log("auth terminerecouvrement", auth);
        const recouvrement = {
            agentToRec,
            activite,
            locality,
            validatedUsers
        };
        console.log("recouvrement", recouvrement);
        try {
            const response = await axiosPrivate.post('/add/recouvrement', recouvrement);
            console.log(response.data);
            // Réinitialiser les états après le succès de la requête
            setActivite("");
            setLocality("");
            setValidatedUsers([]);
            setIsClicked(false);

        } catch (error) {
            console.error('There was an error!', error);

        }
        return () => {
            isMounted = false;
            controller.abort();
        };

    };

    if (locality !== "" && activite !== "") {
        return (
            <div className="container-fluid">
                <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                    <div className="badge text-bg-primary" style={{ float: "left" }}>
                        <p>Nombre de redevables</p>
                        <span>{users.length}</span>
                    </div>
                    <div className="input" style={{ textAlign: "center", display: "contents" }}>
                        <input style={{ marginTop: "10px" }} placeholder="Chercher avec numéro" />
                    </div>
                    <div className="badge text-bg-success" style={{ float: "right" }}>
                        <p>Redevables validés</p>
                        <span>{validatedUsers.length}</span>
                    </div>
                </div>

                <table className="table border">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Prénom</th>
                            <th scope="col">Numéro</th>
                            <th scope="col">Activité</th>
                            <th scope="col" className="text-center">État</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.telephone}>
                                <td>{user.name}</td>
                                <td>{user.surname}</td>
                                <td>{user.telephone}</td>
                                <td>{user.activity}</td>
                                <td className="text-center">
                                    {isUserInRecouvrements(user) ? (
                                        <span>Validé</span>
                                    ) : (
                                        <ValidateButton name={user.name} onValidate={() => handleValidation(user)} />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                    <button type="button" className="text-bg-warning" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ float: "left" }}>
                        Ajouter une annexe
                    </button>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Nouvelle annexe</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Nom</span>
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Prénom</span>
                                        <input type="text" value={surname} onChange={(e) => setSurname(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group col-6" onChange={(e) => setActivity(e.target.value)}>
                                        <InputGroup
                                            name="activities"
                                            value={activity}
                                        />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Téléphone</span>
                                        <input type="text" value={telephone} onChange={(e) => setTelephone(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Commune</span>
                                        <input type="text" value={commune} onChange={(e) => setCommune(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Date de naissance</span>
                                        <input type="date" value={birthday} onChange={(e) => setBirthday(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="input-group col-6" onChange={(e) => setAdressActivite(e.target.value)}>
                                        <InputGroup
                                            name="localities"
                                            value={locality}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer" style={{ display: "block" }}>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                                    <button type="button" className="btn btn-success" onClick={ajouterAnnexe}>Ajouter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="text-bg-danger" style={{ float: "right" }} onClick={terminerRecouvrement}>Terminer le recouvrement</button>
                </div>
            </div>
        );
    } else if ((locality === "" || activite === "") && isClicked === false) {
        return (
            <div className="mt-5 container border">
                <div style={{ textAlign: "center" }}>
                    <h1>Recouvrement</h1>
                    <p>Veuillez choisir une localité et une activité pour commencer le recouvrement.</p>
                </div>
                <div className="input-group col-6" onChange={(e) => setLocality(e.target.value)}>
                    <InputGroup
                        name="localities"
                        value={locality}
                    />
                </div>
                <div className="input-group col-6" onChange={(e) => setActivite(e.target.value)}>
                    <InputGroup
                        name="activities"
                        value={activite}
                    />
                </div>
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "10px", marginTop: "10px" }}>
                    <button type="button" className="btn btn-danger" onClick={effacer} disabled={isClicked}>Effacer</button>
                    <button type="button" className="btn btn-success" onClick={recouvrement}>Recouvrement</button>
                </div>
            </div>
        );
    }
};

export default Agent;
