import { Route, Routes } from 'react-router-dom';
import React from 'react'
import "./App.css"
import Homepage from './components/Homepage';
import Login from './components/Login';
import Agent from './components/Agent';
import Navbar from './components/Navbar';
import Register from './components/Register';
import Admin from './components/admin/Admin';
import Infos from './components/Infos';
import MesAgents from './components/admin/MesAgents';
import Redevable from './components/admin/Redevables.jsx';
import Recouvrement from './components/admin/Recouvrement.jsx';
import Annex from './components/admin/Annex.jsx';
import Cloture from './components/admin/Cloturer.jsx';
import Reglage from './components/admin/Reglage.jsx';
import Layout from './components/layout.jsx';
import RequireAuth from './components/RequireAuth.js';
import Ticket from './components/admin/recouvrement/ticket.jsx';
import Stationnement from './components/admin/recouvrement/stationnement.jsx';
import Souck from './components/admin/recouvrement/souck.jsx';
import Odp from './components/admin/recouvrement/odp.jsx';
import Localities from './components/admin/reglages/Localities.jsx';
import Activities from './components/admin/reglages/Activities.jsx';
import AddAdmin from './components/admin/reglages/AddAdmin.jsx';
import Perso from './components/admin/reglages/perso.jsx';




function App() {
  return (
    <div className="container-fluid">
      <Navbar />
      <Routes>
        <Route path='/' element={<Layout />}>
          {/*public Routes */}
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/Infos" element={<Infos />} />
          <Route exact path="/login" element={<Login />} />

          {/*private Routes */}
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}> {/* accesible a Agent et Admin */}
            <Route exact path="register" element={<Register />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[2]} />}>{/* accesible uniquement a Agent  */}
            <Route path="Agent" element={<Agent />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1]} />}> {/* accesible uniquement a Admin */}
            <Route path="Admin/*" element={<Admin />} />
            <Route path="MesAgents" element={<MesAgents />} />
            <Route path="Redevable" element={<Redevable />} />
            <Route path="Recouvrement" element={<Recouvrement />} />
            <Route path="Annex" element={<Annex />} />
            <Route path="Cloture" element={<Cloture />} />
            <Route path="Reglage" element={<Reglage />} />
            <Route path="Ticket" element={<Ticket />} />
            <Route path="Stationnement" element={<Stationnement />} />
            <Route path="Odp" element={<Odp />} />
            <Route path="Souck" element={<Souck />} />
            <Route path="Activities" element={<Activities />} />
            <Route path="Localities" element={<Localities />} />
            <Route path="AddAdmin" element={<AddAdmin />} />
            <Route path="perso" element={<Perso />} />
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App;
