import axios from "axios";
import useAuth from "./useAuth";


const useRefreshToken = () => {
  
    const { setAuth } = useAuth();

    const refresh = async () => {
        const respose = await axios.get("http://localhost:8000/refresh", {
            withCredentials: true
        });
        setAuth(prev => {
            console.log(JSON.stringify(prev));
            return { ...prev, accessToken: respose.data.accessToken }
        });
        return respose.data.accessToken;
    }
    return refresh
};
export default useRefreshToken;