import React, { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import InputGroup from "../inputGroup.jsx";
import useAxiosPrivate from "../../hooks/useAxiosPrivate.js";

const Annex = () => {

    const [users, setUsers] = useState([]);
    const [locality, setLocality] = useState("");
    const [activite, setActivite] = useState("");
    const [userstocover, setUserstocover] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [validatedUsers, setValidatedUsers] = useState([]);

    const axiosPrivate = useAxiosPrivate()
    const [changedUsers, setChangeUsers] = useState([]); // State for validated users


    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [surname, setSurname] = useState("");
    const commune = "sebikotane";
    const [address_activity, setAdressActivite] = useState("");
    const [telephone, setTelephone] = useState("");
    const [birthday, setBirthday] = useState("");
    const [activity, setActivity] = useState("");


    const [telephoneToSearch, setTelephoneSearch] = useState("");


    const modifier = () => {
        const changedUsers = {
            id,
            name,
            surname,
            birthday,
            telephone,
            activity,
            address_activity,
            commune,
        }
        console.log("je suis cliquer modifier");
        let isMounted = true;
        const controller = new AbortController()
        const UpdateUsers = async () => {
            try {
                const response = await axiosPrivate.put(`/annexe/${id}`, changedUsers, {
                    signal: controller.signal
                });
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        UpdateUsers();
        retourModal();

        return () => {
            isMounted = false;
            controller.abort()
        }
    }

    const handchange = (user) => {

        console.log("user :", user);
        setId(user._id)
        setName(user.name)
        setSurname(user.surname)
        setActivity(user.activity)
        setTelephone(user.telephone)
        setBirthday(user.birthday.slice(0, 10))
        setAdressActivite(user.address_activity)
        setChangeUsers((prevChangeUsers) => [...prevChangeUsers, user]);
        console.log("changedUsers :", changedUsers);


    };

    const recherche = async () => {
        console.log("je suis cliquer", telephoneToSearch);
        const numero = parseInt(telephoneToSearch)
        console.log("je suis cliquer le type", typeof numero);
        console.log("je suis cliquer le length", telephoneToSearch.length);
        console.log("je suis ", parseInt(telephoneToSearch));


        if (typeof numero !== "number") {
            return (
                alert("veiller entre un numero de telephone valide")
            )
        } else if (telephoneToSearch.length !== 9) {
            return (
                alert("veiller entre un numero de telephone avec 9 chiffres sans espace")
            )
        } else {
            const userfind = users.find(user => user.telephone === numero)
            console.log("userfind :", userfind);
            if (userfind === undefined) {
                return (
                    alert("ce numero nexiste pas dans la base de donne")
                )
            } else {

                setUsers([userfind])
                console.log("Users:", users);

            }


        }
    }

    const effacerRecherche = () => {

        setTelephoneSearch("")

    }


    const retourModal = () => {
        setId("")
        setName("")
        setSurname("")
        setActivity("")
        setTelephone("")
        setBirthday("")
        setAdressActivite("")
    }

    const effacer = () => {
        setActivite("");
        setLocality("");
        //window.location.reload();

    };

    const terminerRecouvrement = () => {
        alert(`vous venner de terminer de recouvrir les ${activite} de ${locality}`)
        setActivite("");
        setLocality("");
        console.log("la liste des redevable valider :", validatedUsers);
        setValidatedUsers([])
    };

    const recouvrement = () => {
        if (locality === "" || activite === "") {
            alert("Veuillez bien choisir une localité et une activité");
        } else {
            setUserstocover(users);
            setIsClicked(true);
            console.log("users to cover :", userstocover);
            console.log("isClicked :", isClicked);
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController()
        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/annexe', {
                    signal: controller.signal
                });
                console.log(response.data);
                isMounted && setUsers(response.data)
            } catch (error) {
                console.log(error);
            }
        }
        getUsers();
        return () => {
            isMounted = false;
            controller.abort()
        }

    }, []);

    useEffect(() => {                                   //filtre par locality et activity
        if (locality === "" && activite === "") {
            let isMounted = true;
            const controller = new AbortController()
            const getUsers = async () => {
                try {
                    const response = await axiosPrivate.get('/annex', {
                        signal: controller.signal
                    });
                    console.log(response.data);
                    isMounted && setUsers(response.data)
                } catch (error) {
                    console.log(error);
                }
            }
            getUsers();
            return () => {
                isMounted = false;
                controller.abort()
            }
        } else if (locality !== "" && activite === "") {
            //axios.get(`http://localhost:8000/users/locality/${locality}`)

            let isMounted = true;
            const controller = new AbortController()
            const getUsers = async () => {
                try {
                    const response = await axiosPrivate.get(`/locality/${locality}`, {
                        signal: controller.signal
                    });
                    console.log(response.data.users);
                    isMounted && setUsers(response.data.users)
                } catch (error) {
                    console.log(error);
                }
            }
            getUsers();
            return () => {
                isMounted = false;
                controller.abort()
            }
        } else if (activite !== "" && locality === "") {
            let isMounted = true;
            const controller = new AbortController()
            const getUsers = async () => {
                try {
                    const response = await axiosPrivate.get(`/activity/${activite}`, {
                        signal: controller.signal
                    });
                    console.log(response.data.users);
                    isMounted && setUsers(response.data.users)
                } catch (error) {
                    console.log(error);
                }
            }
            getUsers();
            return () => {
                isMounted = false;
                controller.abort()
            }
        } else {
            let isMounted = true;
            const controller = new AbortController()
            const getUsers = async () => {
                try {
                    const response = await axiosPrivate.get(`/usersfilter/?activity=${activite}&locality=${locality}`, {
                        signal: controller.signal
                    });
                    console.log(response.data);
                    isMounted && setUsers(response.data)
                } catch (error) {
                    console.log(error);
                }
            }
            getUsers();
            return () => {
                isMounted = false;
                controller.abort()
            }
        }
    }, [locality, activite]);

    useEffect(() => {                                   // modal
        const myModalElement = document.getElementById('exampleModal');
        if (myModalElement) {
            const myModal = new Modal(myModalElement);
            //myModal.show();
        }
    }, []);

    return (
        <div className="container-fluid">
            <div className="filter row bg-primary-subtle" style={{ paddingTop: '15px' }}>
                <div className="input-group col-6" style={{ width: '20%' }} onChange={(e) => setLocality(e.target.value)}>
                    <InputGroup
                        name="localities"
                        value={locality}
                    />
                </div>
                <div className="input-group col-6" style={{ width: '20%' }} onChange={(e) => setActivite(e.target.value)}>
                    <InputGroup
                        name="activities"
                        value={activite}
                    />
                </div>
                <div className="input-group col-6" style={{ width: '15%' }}>
                    <button type="button" className="btn btn-danger" style={{ height: 'fit-content' }} onClick={effacer} disabled={isClicked}>Effacer</button>
                </div>
                <div className="input-group col-6" style={{ width: '0%' }}>
                    <button type="button" className="btn btn-success" style={{ height: 'fit-content' }} onClick={recouvrement}>Recouvrement</button>
                </div>
            </div>
            <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                <div className="badge text-bg-primary" style={{ float: "left" }}>
                    <p>Nombre d'agents</p>
                    <span>{users.length}</span>
                </div>
                <div className="input" style={{ textAlign: "center", display: "contents", float: "right" }}>
                    <input style={{ marginTop: "10px" }} placeholder="Chercher avec numéro" onChange={(e) => setTelephoneSearch(e.target.value)} />
                    <button onClick={recherche}>Rechercher</button>
                    <button onClick={effacerRecherche} >Effacer</button>
                </div>
            </div>

            <table className="table border">
                <thead>
                    <tr>
                        <th scope="col">Nom</th>
                        <th scope="col">Prénom</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Localité</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.telephone}>
                            <td>{user.name}</td>
                            <td>{user.surname}</td>
                            <td>{user.telephone}</td>
                            <td>{user.activity}</td>
                            <td>{user.address_activity}</td>
                            <td><button className="text-bg-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" name={user.name} onClick={() => handchange(user)}>Modifier</button></td>
                        </tr>
                    ))}
                </tbody>

            </table>

            <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                {/*<button type="button" className="text-bg-warning" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ float: "left" }}>
                    Ajouter une annexe
                </button>*/}
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Nom</span>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Prénom</span>
                                    <input type="text" value={surname} onChange={(e) => setSurname(e.target.value)} className="form-control" />
                                </div>
                                <div className="input-group col-6" value={activity} onChange={(e) => setActivity(e.target.value)}>
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Activité</span>
                                    <span className="input-group-text" id="inputGroup-sizing-sm">{activity}</span>
                                    <InputGroup
                                        name="activities"
                                        value={activity}
                                    />
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Téléphone</span>
                                    <input type="text" value={telephone} onChange={(e) => setTelephone(e.target.value)} className="form-control" />
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Date de naissance</span>
                                    <input type="date" value={birthday} onChange={(e) => setBirthday(e.target.value)} className="form-control" />
                                </div>
                                <div className="input-group col-6" value="" onChange={(e) => setAdressActivite(e.target.value)}>
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Adresse d'activité</span>
                                    <span className="input-group-text" id="inputGroup-sizing-sm">{address_activity}</span>
                                    <InputGroup
                                        name="localities"
                                        value={address_activity}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer" style={{ display: "block" }}>
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={retourModal} >Fermer</button>
                                <button type="button" className="btn btn-success" onClick={modifier} >Modifer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Annex;
