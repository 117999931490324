import React from 'react';
import { Link } from 'react-router-dom';
import './Admin.css';
import Circle from './Circle';

function Recouvrement() {
    return (

        <div className='admincontent'>

            <h1>Recouvrement
            </h1>
            <div className="content">

                <Link to="/Ticket">
                    <Circle color="blue" label="Ticket" icon="bi bi-ticket-perforated" />
                </Link>
                <Link to="/Stationnement">
                    <Circle color="yellow" label="Stationnement" icon="bi bi-car-front-fill" />
                </Link>
            </div>
            <div className="content">
                <Link to="/Souck">
                    <Circle color="yellow" label="Souck" icon="bi bi-house-door" />
                </Link>
                <Link to="/Odp">
                    <Circle color="pink" label="Odp" icon="bi bi-shop" />
                </Link>
            </div>
        </div>

    );
}

export default Recouvrement;

