import React, { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Activities = () => {

    const axiosPrivate = useAxiosPrivate();
    const [changedactivities, setChangeUsers] = useState([]); // State for validated localities

    const [activities, setLocalities] = useState([]);
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [id, setId] = useState("");
    const [newName, setNewName] = useState("");
    const [newAmount, setNewAmount] = useState("");

    const modifier = () => {
        const changedActivity = {
            id,
            name,
            amount,
        };
        console.log("je suis cliquer modifier");
        console.log("changedActivity", changedActivity);
        let isMounted = true;
        const controller = new AbortController();
        const Updatelocality = async () => {
            console.log("id", id);
            try {
                const response = await axiosPrivate.put(`/activity/${id}`, changedActivity, {
                    signal: controller.signal
                });
                console.log(response.data);
                alert("Modification effectuée");
            } catch (error) {
                console.log(error);
            }
        };
        Updatelocality();
        retourModal();

        return () => {
            isMounted = false;
            controller.abort();
        };
    };

    const addActivity = () => {
        const newActivity = {
            name: newName,
            amount: newAmount,
        };
        let isMounted = true;
        const controller = new AbortController();
        const createActivity = async () => {
            try {
                const response = await axiosPrivate.post('/add/activity', newActivity, {
                    signal: controller.signal
                });
                console.log(response.data);
                if (isMounted) {
                    setLocalities((prevLocalities) => [...prevLocalities, response.data]);
                    alert("Activité ajoutée");
                }
            } catch (error) {
                console.log(error);
            }
        };
        createActivity();
        retourNewModal();

        return () => {
            isMounted = false;
            controller.abort();
        };
    };

    const handchange = (activity) => {
        console.log("activity :", activity);
        setId(activity._id);
        setName(activity.name);
        setAmount(activity.amount);
        setChangeUsers((prevChangeActivities) => [...prevChangeActivities, activity]);
        console.log("changedactivities :", changedactivities);
    };

    const retourModal = () => {
        setId("");
        setName("");
        setAmount("");
    };

    const retourNewModal = () => {
        setNewName("");
        setNewAmount("");
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getActivities = async () => {
            try {
                const response = await axiosPrivate.get('/activities', {
                    signal: controller.signal
                });
                console.log(response.data);
                isMounted && setLocalities(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getActivities();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, activities]);

    useEffect(() => { // modal
        const myModalElement = document.getElementById('exampleModal');
        if (myModalElement) {
            const myModal = new Modal(myModalElement);
            //myModal.show();
        }
    }, []);

    return (
        <div className="container-fluid">
            <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                <div className="badge text-bg-primary" style={{ float: "left" }}>
                    <p>Nombre d'activités</p>
                    <span>{activities.length}</span>
                </div>
            </div>

            <table className="table border">
                <thead>
                    <tr>
                        <th scope="col">Nom</th>
                        <th scope="col">Montant</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {activities.map(locality => (
                        <tr key={locality._id}>
                            <td>{locality.name}</td>
                            <td>{locality.amount}</td>
                            <td><button className="text-bg-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" name={activities.name} onClick={() => handchange(locality)}>Modifier</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="container-fluid" style={{ textAlign: "center", display: "block" }}>
                <button type="button" className="text-bg-warning" data-bs-toggle="modal" data-bs-target="#addActivityModal" style={{ float: "left" }}>
                    Ajouter une activité
                </button>

                {/* Modal pour modifier une activité */}
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Nom</span>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Montant</span>
                                    <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className="form-control" />
                                </div>
                            </div>
                            <div className="modal-footer" style={{ display: "block" }}>
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={retourModal}>Fermer</button>
                                <button type="button" className="btn btn-success" onClick={modifier}>Modifier</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal pour ajouter une nouvelle activité */}
                <div className="modal fade" id="addActivityModal" tabIndex="-1" aria-labelledby="addActivityModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Nom</span>
                                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} className="form-control" />
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Montant</span>
                                    <input type="number" value={newAmount} onChange={(e) => setNewAmount(e.target.value)} className="form-control" />
                                </div>
                            </div>
                            <div className="modal-footer" style={{ display: "block" }}>
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={retourNewModal}>Fermer</button>
                                <button type="button" className="btn btn-success" onClick={addActivity}>Ajouter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Activities;
