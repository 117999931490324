import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate.js";
import { Modal } from "bootstrap";
import useAuth from "../../../hooks/useAuth.js";
import { useNavigate } from "react-router-dom";

const ODP = () => {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [recouvrement, setRecouvrement] = useState([]);
    const [clotures, setClotures] = useState([]);
    const [validatedUsers, setValidatedUsers] = useState([]);
    const [amount, setAmount] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [validatedRecouvrement, setValidatedRecouvrement] = useState([]);
    const [currentRecouvrementIndex, setCurrentRecouvrementIndex] = useState(0);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAmount = async () => {
            try {
                const response = await axiosPrivate.get(`/activities`, {
                    signal: controller.signal
                });

                if (isMounted) {
                    const activite = response.data.filter(elem => elem.name === "ODP");
                    setAmount(activite[0].amount);
                }
            } catch (error) {
                console.log(error);
            }
        };

        getAmount();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getRecouvrement = async () => {
            try {
                const [response, clotures] = await Promise.all([
                    axiosPrivate.get(`/recouvrements`, { signal: controller.signal }),
                    axiosPrivate.get('/clotures', { signal: controller.signal })

                ])

                if (isMounted) {
                    setRecouvrement(response.data.filter(elem => elem.activite === "ODP"));
                    setClotures(clotures.data);
                    console.log("clotures", clotures);
                }
            } catch (error) {
                console.log(error);
            }
        };

        getRecouvrement();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, recouvrement, clotures]);

    const handleShowUsers = async (recouvrementId) => {
        try {
            const users = recouvrement[recouvrementId].validatedUsers;
            setValidatedUsers(users || []);
            setIsVisible(true);
            setCurrentRecouvrementIndex(recouvrementId);  // Enregistrer l'index actuel
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const myModalElement = document.getElementById('exampleModal');
        if (myModalElement) {
            const myModal = new Modal(myModalElement);
        }
    }, []);

    const valider = () => {
        const currentRec = recouvrement[currentRecouvrementIndex];  // Utiliser l'index actuel
        let objet = {
            users: validatedUsers,
            agent: currentRec.agentToRec,
            locality: currentRec.locality,
            activite: currentRec.activite,
            admin: auth.surname,
            amountActivity: amount,
            totalUsers: validatedUsers.length
        };

        let objets = [...validatedRecouvrement, objet];
        setValidatedRecouvrement(objets);
        console.log("validatedRecouvrement", validatedRecouvrement);
    };

    const isRecouvrementValidated = (rec) => {
        return clotures.some(cloture =>
            cloture.objets.some(obj =>
                obj.activite === rec.activite &&
                obj.locality === rec.locality &&
                JSON.stringify(obj.users) === JSON.stringify(rec.validatedUsers)
            )
        );
    };

    const Etat = ({ rec }) => {
        const isValidated = isRecouvrementValidated(rec);
        return (
            <p style={{ color: isValidated ? "green" : "red" }}>
                {isValidated ? "Validée" : "Non validée"}
            </p>
        );
    };

    const terminer = async () => {
        console.log("validatedRecouvrement", validatedRecouvrement);
        try {
            const response = await axiosPrivate.post('/add/cloture', { objets: validatedRecouvrement });
            console.log(response.data);
            navigate(-1)

        } catch (error) {
            console.error('Erreur lors de la clôture:', error);
        }
    };

    return (
        <div>
            <h1>ODP</h1>
            {recouvrement.length > 0 ? (
                <div>
                    <table className="table border">
                        <thead>
                            <tr>
                                <th scope="col">Localité</th>
                                <th scope="col">Activité</th>
                                <th scope="col" className="text-center"></th>
                                <th scope="col" className="text-center">Agent</th>
                                <th scope="col" className="text-center">Etat</th>
                                <th scope="col" className="text-center" style={{ float: "right" }}>Redevables recouverts</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recouvrement.map((rec, i) => (
                                <React.Fragment key={i}>
                                    <tr>
                                        <td>{rec.locality}</td>
                                        <td>{rec.activite}</td>
                                        <td className="text-center"></td>
                                        <td className="text-center">{rec.agentToRec}</td>
                                        <td className="text-center"><Etat rec={rec} /></td>
                                        <td className="text-center">
                                            <button
                                                type="button"
                                                className={`text-bg-${isRecouvrementValidated(rec) ? 'success' : 'warning'}`}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                onClick={() => handleShowUsers(i)}
                                                style={{ float: "right" }}
                                                disabled={isRecouvrementValidated(rec)}
                                            >
                                                {isRecouvrementValidated(rec) ? 'Liste Validée' : 'Afficher la liste'}
                                            </button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <tr>
                        <td>
                            <button className="badge text-bg-primary" onClick={terminer}>terminer</button>
                        </td>
                    </tr>
                </div>
            ) : (
                <p>Aucun recouvrement trouvé.</p>
            )}

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Nouvelle annexe</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {validatedUsers.length > 0 ? (
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Prénom</th>
                                            <th>Nom</th>
                                            <th>Téléphone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {validatedUsers.map((user, index) => (
                                            <tr key={index}>
                                                <td>{user.surname}</td>
                                                <td>{user.name}</td>
                                                <td>{user.telephone}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="badge text-bg-primary" style={{ float: "left" }}>
                                                    <p>Total redevables</p>
                                                    <span>{validatedUsers.length}</span>
                                                </div>
                                            </td>
                                            <td colSpan="3">
                                                <div className="badge text-bg-primary" style={{ float: "left" }}>
                                                    <p>Prix</p>
                                                    <span>{amount}</span>
                                                </div>
                                            </td>
                                            <td colSpan="3">
                                                <div className="badge text-bg-success" style={{ float: "left" }}>
                                                    <p>Total</p>
                                                    <span>{validatedUsers.length * amount}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            ) : (
                                <p>La liste est vide.</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close">Fermer</button>
                            <button type="button" className="btn btn-success" data-bs-dismiss="modal" aria-label="Close" onClick={valider}>Valider</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ODP;
