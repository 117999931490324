import React from "react";
import "./component.css";
import { useNavigate } from "react-router-dom";

const Homepage = () => {
  const navigate = useNavigate();
  
  return (
    <div className="full-page-background">
      <div className="content-wrapper">
        <div className="bodypage">
          <p className="welcome-text">Bienvenue sur notre plateforme digitale de recouvrement</p>
          <button className="connect-button" onClick={() => navigate("/Login")}>Connectez-vous</button>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
