import React from "react";
import { Link } from 'react-router-dom';
import './Admin.css';
import Circle from './Circle';

const Reglage = () => {
    return (
        <div className='admincontent'>
            <h1>Réglages</h1>
            <div className="content">
                <Link to="/Localities">
                    <Circle color="blue" label="Localités" icon="bi bi-pin-map-fill" />
                </Link>
                <Link to="/Activities">
                    <Circle color="pink" label="Activités" icon="bi bi-activity" />
                </Link>
            </div>
            <div className="content">
                <Link to="/AddAdmin">
                    <Circle color="yellow" label="Administrateur" icon="bi bi-person-plus-fill" />
                </Link>
                <Link to="/Perso">
                    <Circle color="blue" label="Infos personnelles" icon="bi bi-person-gear" />
                </Link>
            </div>
        </div>
    );

}
export default Reglage