import React, { useState } from 'react';
import { NavLink} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import axios from 'axios';

const Navbar = () => {
    const { auth, setAuth } = useAuth();
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const logout = async () => {
        try {
            const response = await axios.get("http://localhost:8000/logout");
            console.log(response);
            setAuth(null); // Réinitialiser le contexte d'authentification
            alert("Vous venez de vous déconnecter !");
        } catch (error) {
            console.log(error);
        }
    }

    const navbarClass = isCollapsed ? "navbar-collapse collapse" : "navbar-collapse collapse show";

    return (
        <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
            <div className="container-fluid">
                <NavLink className="navbar-brand" to="/">Accueil</NavLink>
                <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={navbarClass}>
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link active" to="/Infos">Infos</NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        {!auth?.email ? (
                            <>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Login">Connexion</NavLink>
                                </li>
                            </>
                        ) : (
                            <>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Register">Inscription</NavLink>
                                </li>
                                {auth?.role === 2 && (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/Agent">Agent</NavLink>
                                    </li>
                                )}
                                {auth?.role === 1 && (
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/Admin">Admin</NavLink>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <button type="button" className="btn btn-danger" onClick={logout}>Déconnexion</button>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
