import React from "react";
import './Admin.css';
import Content from './Content';



const Admin = () => {
    return (

        <div >
            <Content className="AdminContent" />
        </div>

    )
}


export default Admin