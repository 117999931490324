
import React from 'react'
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import useAxiosPrivate from "../hooks/useAxiosPrivate";


function InputGroup(props) {

    const axiosPrivate = useAxiosPrivate()

    const [info, setInfo] = useState([])

    useEffect(function () {
        async function fetchData() {
            let isMounted = true;
            const controller = new AbortController()

            try {
                const response = await axiosPrivate.get(`${props.name}`, {
                    signal: controller.signal
                })
                    setInfo(response.data);
            } catch (error) {
                console.error("There was an error fetching the data:", error);
            }
        }

        fetchData();
    }, [props.name])

    return (
        <div className="input-group mb-3">
            <select className="form-select" id="inputGroupSelect02">
                <option key={props.name} selected disabled>{props.name}...</option>
                {info.map((elem, index) => {
                    return (<option key={index}>{elem.name}</option>)
                })}
            </select>
        </div>
    )

}

export default InputGroup;
